import { Button, Divider, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/authContext'
import { isAdmin } from "../../utils/userData";
import "./header.scss"

export default function Header({ backgroundColor }) {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleClick = (ref) => {
        navigate(ref)
    }

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.log("error", error);
        }
    };

    return (

        <Grid container item xs={12} className="header-wrapper" style={{ backgroundColor: "white" }} justifyContent="center">
            <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/')}>Clientes</Button>
            <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
            <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/contracts')}>Análise de Contratos</Button>
            <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
            <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/agr-gpt/accounts')}>AGR-GPT</Button>
            <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
            {isAdmin() && (
                <>
                    <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/users')}>Usuários</Button>
                    <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
                </>
            )}
            <Button style={{ textTransform: 'none' }} onClick={() => handleLogout()}>Sair</Button>
        </Grid>
    )
}