import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import MainLayout from '../../../layouts'
import {
    Button,
    CircularProgress,
    Box,
    Paper,
    Typography,
    IconButton,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadNotice, getNotices, deleteNotice } from '../../../utils/api';
import moment from 'moment-timezone';

const ContractListPage = () => {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState('Nacional');
    const [fromType, setFromType] = useState('');
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showGrid, setShowGrid] = useState(true);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [columns] = useState([
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'title', headerName: 'Título', width: 600 },
        { field: 'created_at', headerName: 'Criado Em', width: 225 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => navigate(`/contracts/${params.id}`)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => navigate(`/agr-gpt/contracts/${params.id}`)}
                    >
                        <ChatIcon />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDelete(params.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ]);

    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);

        if (event.target.value === 'Nacional')
            setFromType('');
    };

    const handleFromType = (event) => {
        setFromType(event.target.value);
    };

    const fetchNotices = async () => {
        try {
            const response = await getNotices();

            setRows(response.data.notices.map((notice) => ({
                id: notice.id,
                title: notice.title,
                type: notice.type + (notice.from_type ? ` | ${notice.from_type}` : ''),
                created_at: moment(notice.created_at).tz(userTimeZone).format('DD/MM/YYYY [às] HH:mm:ss')
            })));
        } catch (error) {
            toast.error('Falha ao buscar contratos');
        }
    }

    const handleDelete = async (noticeId) => {
        try {
            const response = await deleteNotice(noticeId);

            setRows((prevRows) => prevRows.filter((notice) => notice.id !== noticeId));

            toast.success("Contrato excluído!");
        } catch (error) {
            // console.log("error", error)
            toast.error('Falha ao excluir contrato');
        }
    }

    useEffect(() => {
        document.title = 'OBZ | Análise de Contratos'
        fetchNotices();
    }, [])

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([]);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const removeFile = (fileName) => {
        if (loading) return;

        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const handleBack = () => {
        setShowGrid(true);
    }

    const handleUpload = async () => {
        if (files.length === 0) {
            toast.error('Selecione arquivos para fazer upload!');
            return;
        }

        setLoading(true);

        try {
            let payload = new FormData();

            files.forEach((file) => payload.append('files', file));

            payload.append('is_national', Boolean(fileType == 'Nacional'));
            payload.append('from_type', fromType != '' ? fromType : null);

            const response = await uploadNotice(payload);
            const noticeId = response.data.id;

            toast.success("Sucesso!");
            localStorage.setItem('processNotice', true);
            setTimeout(() => {
                navigate(`/contracts/${noticeId}`)
            }, 2000);
        } catch (error) {
            toast.error('Falha ao realizar upload');
            setLoading(false);
        }
    };

    const handleNewUpload = () => {
        setShowGrid(false);
        setFiles([]);
    };

    return (
        <MainLayout>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
                <ToastContainer />
                <Paper sx={{ padding: 4, width: '100%', maxWidth: 1200, boxShadow: 3, margin: { xs: 1, sm: 2 } }}>
                    {!showGrid ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ display: 'flex', alignSelf: 'flex-start' }}>
                                    <IconButton onClick={handleBack} sx={{ marginRight: 1, marginBottom: 1 }}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <Typography variant="h6" gutterBottom sx={{ marginTop: 0.5 }}>
                                        Upload de contratos
                                    </Typography>
                                </Box>


                                <Button
                                    sx={{ width: '100%', ml: { sm: '10%' } }}
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Selecione os arquivos
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileChange}
                                        disabled={loading}
                                    />
                                </Button>
                            </Box>

                            <Box
                                sx={{
                                    flex: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {files.length > 0 && (
                                    <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
                                        {files.map((file) => (
                                            <ListItem key={file.name} >
                                                <ListItemText primary={file.name} primaryTypographyProps={{ fontSize: '0.8rem' }} />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => removeFile(file.name)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </Box>

                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    onClick={handleUpload}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: '50%',
                                        backgroundColor: '#f44336',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#d32f2f',
                                        },
                                    }}
                                    disabled={loading || files.length === 0}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : <CloudUploadIcon fontSize="large" />}
                                </IconButton>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Análise de Contratos
                            </Typography>
                            <Box sx={{ height: 400, width: '100%', mt: 2, marginX: { xs: 1, sm: 2 } }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    pagination
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'id', sort: 'asc' }],
                                        },
                                    }}
                                />
                            </Box>
                            <Button
                                variant="outlined"
                                onClick={handleNewUpload}
                                sx={{ mt: 2 }}
                            >
                                Fazer novo upload
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => navigate('/agr-gpt/contracts')}
                                sx={{ mt: 2, ml: 2 }}
                            >
                                Fale com seus contratos
                            </Button>
                        </>
                    )}
                </Paper>
            </Box>
        </MainLayout>
    );
};

export default ContractListPage;
