import MainLayout from '../../../layouts'
import { Avatar, Box, Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material"
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import NewClientModal from '../../../components/Clients/NewClientModal';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getCustomers } from '../../../utils/api';

const ClientCard = ({ customer }) => {
    const navigate = useNavigate();

    const handleClientClick = () => {
        navigate(`/client/${customer.id}`)
    }

    return (
        <Grid container item xs={6}>
            <Paper elevation={2} sx={{
                width: "100%", height: "100px", cursor: "pointer", "&:hover": {
                    boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)"
                }
            }} onClick={handleClientClick}>
                <Grid container alignItems="center" justifyContent="flex-start" sx={{ height: "100%" }}>
                    <Avatar src={customer.image_link} variant="square" sx={{ height: "100%", width: "100px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }} />
                    <Box mx={2} />
                    <Typography variant="body1">{customer["name"]}</Typography>
                </Grid>
            </Paper>
        </Grid >
    )
}

const ClientsListPage = () => {
    const [showModal, setShowModal] = useState(false)
    const [customers, setCustomers] = useState([])

    const handleAddClientClick = () => {
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    useEffect(() => {
        getCustomers().then(({ data: { customers } }) => setCustomers(customers)).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        console.log(customers)
    }, [customers])

    return (
        <MainLayout>
            <Paper elevation={2} sx={{ width: "100%" }}>
                <Box px={4} py={2}>
                    <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                        <Typography variant="h5">Clientes</Typography>
                        <Grid container justifyContent="flex-end">
                            <TextField
                                name="find-client"
                                label="Pesquisar cliente"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                // value={ledgerAccount}
                                sx={{ "& .MuiInputBase-root": { height: "36px" } }}
                            // onChange={onInputChange}
                            />
                            <Box mx={2} />
                            <Button variant="contained" onClick={handleAddClientClick}>adicionar novo cliente</Button>
                        </Grid>
                    </Grid>
                    <Box pt={2} mb={6}>
                        <Divider sx={{ width: "calc(100% + 64px)", marginLeft: "-32px" }} />
                    </Box>
                    {customers.length ?


                        <Grid container spacing={4} sx={{
                            height: "500px",
                            padding: "0 16px 48px",
                            overflow: "auto",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}>
                            {customers.map(customer => <ClientCard customer={customer} />)}
                        </Grid>
                        : <Grid container alignItems="center" justifyContent="center" spacing={4} sx={{
                            height: "500px",
                            padding: "0 16px 48px"
                        }}>
                            <Typography variant="body1">Ainda não existem clientes cadastrados.</Typography>
                        </Grid>
                    }
                </Box>
            </Paper>
            <NewClientModal showModal={showModal} handleClose={handleModalClose} />
        </MainLayout >
    )

}

export default ClientsListPage