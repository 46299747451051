import { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Box,
    Card,
    FormGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    Grid,
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input'
import { ClipLoader } from "react-spinners";
import { uploadFile } from "../../../utils/api";
import ProcessFileContext from '../../../contexts/processFileContext';
import StepperContext from '../../../contexts/stepperContext';
import { useSnackbar } from 'notistack'

function ProcessingContent({ projectId, fetchData }) {
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { goNext } = useContext(StepperContext)
    const { setUploadedFilePath, processingSteps, setProcessingSteps, willEditLedgerAccount, setWillEditLedgerAccount, setS3UploadedFilePath } = useContext(ProcessFileContext)
    const { enqueueSnackbar } = useSnackbar()

    const handleFileChange = (newFile) => {
        setFile(newFile)
    }

    const handleProcessingStepsChange = ({ target }) => {
        const { checked, value } = target

        if (checked) {
            setProcessingSteps([...processingSteps, value])
        } else {
            setProcessingSteps(processingSteps.filter(step => step !== value))
        }
    }

    const isChecked = (step) => {
        return !!processingSteps.find(processingStep => processingStep === step)
    }

    const handleSubmit = () => {
        const formData = new FormData()
        formData.append("file", file)

        setIsLoading(true)

        uploadFile(projectId, formData).then(
            ({ data: { tmp_file, project_file } }) => {
                fetchData()
                setUploadedFilePath(tmp_file)
                setS3UploadedFilePath(project_file["file_path"])
                enqueueSnackbar({ message: "Arquivo carregado com sucesso!", variant: "success" })
                goNext()
            }).catch((response) => {
                const { detail } = response
                console.log("response => ", response)
                enqueueSnackbar({ message: detail, variant: "error" })
                setIsLoading(false)
            })
    }

    const handleEditLedgerAccountChange = (evt) => {
        const { value } = evt.target

        setWillEditLedgerAccount(value)
    }

    return (
        <Grid container justify="center" direction="column" sx={{ width: "400px" }}>
            <Box sx={{ mb: 3, mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Você deseja processar quais etapas?
                    </strong>
                </Typography>
                <FormGroup>
                    <FormControlLabel disabled control={<Checkbox checked size="small" />} label="Etapa Proposta" />
                    <FormControlLabel control={<Checkbox checked={isChecked("PLANNING_STEP")} onChange={handleProcessingStepsChange} value="PLANNING_STEP" size="small" />} label="Etapa Planejamento" />
                </FormGroup>
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Você deseja alterar as configurações de contas razão?
                    </strong>
                </Typography>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={willEditLedgerAccount}
                        onChange={handleEditLedgerAccountChange}
                    >
                        <FormControlLabel value="y" control={<Radio />} label="Sim" />
                        <FormControlLabel value="n" control={<Radio />} label="Não" />
                    </RadioGroup>
                </FormControl>
            </Box>

            {/* <Box sx={{ mb: 3 }}>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Você deseja carregar um arquivo novo ou selecionar um arquivo já carregado?
                    </strong>
                </Typography>
                <FormControl>
                    <RadioGroup
                        row
                        value={willEditLedgerAccount}
                        onChange={handleEditLedgerAccountChange}
                    >
                        <FormControlLabel value="y" control={<Radio />} label="Carregar um arquivo novo" />
                        <FormControlLabel value="n" control={<Radio />} label="Selecionar um arquivo já carregado" />
                    </RadioGroup>
                </FormControl>
            </Box> */}

            <Box sx={{ mb: 6 }}>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Selecione o arquivo a ser processado:
                    </strong>
                </Typography>
                <MuiFileInput placeholder="Clique aqui para escolher o arquivo" value={file} inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }} onChange={handleFileChange} sx={{ width: "100%" }} />
            </Box>

            <Button variant="contained" color="primary" sx={{ mb: 2, width: "100%" }} disabled={!(file && !isLoading)} onClick={handleSubmit}>
                {isLoading ? <>
                    <ClipLoader size={18} color="white" />
                    <Box ml={3} />
                    Aguarde um momento...
                </> : "Iniciar o Pré-Processamento"}

            </Button>
        </Grid>
    );
}

export default ProcessingContent;