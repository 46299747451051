import axiosInstance from "./apiConfig";

export const login = async (data) => {
    try {
        const response = await axiosInstance.post('/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const response = await axiosInstance.post('/refresh-token');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkStatus = async () => {
    return await axiosInstance.get(`/`)
}

export const uploadFile = async (customerId, file) => {
    return await axiosInstance.post(`/projects/${customerId}/files`, file, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const processFile = async (filepath, processingSteps, projectId) => {
    return await axiosInstance.post('/process-file', { file_path: filepath, processing_steps: processingSteps, project_id: projectId }, {
        responseType: 'blob'
    })
}

export const getLedgerAccounts = async (projectId) => {
    return await axiosInstance.get(`/ledger-accounts/${projectId}`)
}

export const getLedgerAccountByName = async (projectId, description) => {
    return await axiosInstance.get(`/ledger-account/${projectId}/?description=${description}`)
}

export const addLedgerAccount = async (payload) => {
    return await axiosInstance.post(`/ledger-accounts`, payload)
}

export const editLedgerAccount = async (payload) => {
    return await axiosInstance.put('/ledger-accounts', payload)
}

export const deleteLedgerAccount = async (id) => {
    return await axiosInstance.delete(`/ledger-accounts/${id}`)
}

export const getColumnSynonyms = async (projectId) => {
    return await axiosInstance.get(`/column-synonyms/${projectId}`)
}

export const addColumnSynonym = async (payload) => {
    return await axiosInstance.post(`/column-synonyms`, payload)
}

export const deleteColumnSynonym = async (id) => {
    return await axiosInstance.delete(`/column-synonyms/${id}`)
}

export const deleteColumnSynonymByName = async (project_id, description) => {
    return await axiosInstance.delete(`/column-synonyms-by-name/${project_id}/?description=${description}`)
}

export const getOutLedgerAccounts = async (projectId) => {
    return await axiosInstance.get(`/out-ledger-account/${projectId}`)
}

export const addOutLedgerAccount = async (payload) => {
    return await axiosInstance.post(`/out-ledger-account`, payload)
}

export const deleteOutLedgerAccount = async (id) => {
    return await axiosInstance.delete(`/out-ledger-account/${id}`)
}

export const getWorkGroups = async (projectId) => {
    return await axiosInstance.get(`/work-groups/${projectId}`)
}

export const addWorkGroups = async (payload) => {
    return await axiosInstance.post(`/work-groups`, payload)
}

export const deleteWorkGroup = async (id) => {
    return await axiosInstance.delete(`/work-groups/${id}`)
}

export const identifyAccounts = async (filepath, processingSteps, projectId) => {
    return await axiosInstance.post(`/identify-accounts`, { file_path: filepath, processing_steps: processingSteps, project_id: projectId })
}


export const saveIdentifiedAccounts = async (identifiedAccounts, projectId) => {
    return await axiosInstance.post(`/save-identified-accounts`, { identified_accounts: identifiedAccounts, project_id: projectId })
}

export const userRegister = async (payload) => {
    try {
        const response = await axiosInstance.post('/users', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userUpdate = async (payload, userId) => {
    try {
        const response = await axiosInstance.put(`/users/${userId}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listUsers = async (queryParams) => {
    try {
        const response = await axiosInstance.get('/users', {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (userId) => {
    try {
        const response = await axiosInstance.get(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCustomers = async (payload) => {
    return await axiosInstance.get(`/customers`)
}

export const getCustomerById = async (customerId) => {
    return await axiosInstance.get(`/customers/${customerId}`)
}

export const addCustomer = async (payload) => {
    return await axiosInstance.post(`/customers`, payload)
}

export const editCustomer = async (customerId, payload) => {
    return await axiosInstance.put(`/customers/${customerId}`, payload)
}

export const deleteCustomer = async (customerId) => {
    return await axiosInstance.delete(`/customers/${customerId}`)
}

export const addProject = async (customerId, payload) => {
    return await axiosInstance.post(`/customers/${customerId}/projects/`, payload)
}

export const getProject = async (clientId, projectId) => {
    return await axiosInstance.get(`/customers/${clientId}/projects/${projectId}`)
}

export const editProject = async (customerId, projectId, payload) => {
    return await axiosInstance.put(`/customers/${customerId}/projects/${projectId}`, payload)
}

export const deleteProject = async (customerId, projectId) => {
    return await axiosInstance.delete(`/customers/${customerId}/projects/${projectId}`)
}

export const getNotices = async () => {
    return await axiosInstance.get(`/contracts`);
}

export const uploadNotice = async (payload) => {
    return await axiosInstance.post(`/contracts`, payload)
}

export const deleteNotice = async (noticeId) => {
    return await axiosInstance.delete(`/contracts/${noticeId}`);
}

export const getNotice = async (noticeId) => {
    return axiosInstance.get(`/contracts/${noticeId}`);
}

export const processNotice = async (data) => {
    return axiosInstance.post(`/contracts/analysis`, data)
}


export default {}
