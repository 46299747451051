import React from "react";
import { Grid } from '@mui/material'
import './gpt-layout.scss'
import Header from "../../components/Header";

function GptLayout({ children }) {
    return (
        <Grid container className="gpt-container" justifyContent="center">
            <Header backgroundColor="white" />
            <Grid container alignItems="center" justifyContent="center" item xs={12} className="gpt-container__content">
                {children}
            </Grid>
        </Grid >
    )
}

export default GptLayout