import { useEffect, useState } from 'react'
import GptLayout from "../../layouts/gpt"
import { Box, Button, Drawer, Grid, Paper, Skeleton, Typography } from '@mui/material'
import Chat from '../../components/QuestionsAndAnswers/Chat'
import QuestionBar from '../../components/QuestionsAndAnswers/QuestionBar'
import AutoCompleteSelect from '../../components/AutoComplete'
import { getCustomers, getCustomerById } from '../../utils/api'

const QuestionAndAnswers = () => {
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState()
    const [isLoading, setIsLoading] = useState(false)
    
    const [context, setContext] = useState([{
        "user_type": "system",
        "message_type": "text",
        "message": "Como posso ajudar?",
        "id": 5
    }])

    const handleSimpleChange = (value) => {
        setSelectedCustomer(value)
    }

    const handleProjectChange = (value) => {
        setSelectedProject(value)
    }

    useEffect(() => {
        setIsLoading(true)
        getCustomers().then(({ data: { customers } }) => {
            setIsLoading(false)
            setCustomers(customers.map(({ id, name }) => ({ label: name, value: id })))
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (selectedCustomer) {
            setIsLoading(true)
            getCustomerById(selectedCustomer).then(({ data: { projects } }) => {
                setIsLoading(false)
                setProjects(projects.map(({ id, name }) => ({ label: name, value: id })))
            }).catch(err => {
                setIsLoading(false)
                console.log(err)
            })
        } else {
            setProjects([])
            setSelectedProject(undefined)
        }
    }, [selectedCustomer])

    return (
        <GptLayout>
            {/* <Drawer
                variant="permanent"
                sx={{
                    width: '300px',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '300px', boxSizing: 'border-box', height: 'calc(100vh - 60px)', top: '60px', backgroundColor: '#f5f5f5' },
                    ['& .MuiGrid-container']: { justifyContent: "center" }
                }}
            >
                <Grid container sx={{ width: "100%", padding: '16px', overflow: 'hidden' }} direction="column">
                    <Typography variant="h6" style={{ textAlign: "center" }}>Filtros</Typography>
                    <Box py={1} />
                    <AutoCompleteSelect label={"Cliente"} selectedValue={selectedCustomer} onChange={handleSimpleChange} options={customers} />
                    <Box my={1} />
                    <AutoCompleteSelect label={"Projetos"} selectedValue={selectedProject} onChange={handleProjectChange} options={projects} />
                </Grid>
            </Drawer> */}
            <Grid container item direction="column" wrap="nowrap" xs={8} style={{ height: "100%", padding: "30px 0 10px" }}>
                <Chat context={context} setContext={setContext} />
                <QuestionBar
                    context={context}
                    selectedProject={selectedProject}
                    selectedCustomer={selectedCustomer}
                    setContext={setContext}
                />
            </Grid>
        </GptLayout >
    )
}

export default QuestionAndAnswers